import styled from 'styled-components'
import BaseLayout from 'components/BaseLayout'
import ImgArrowRight from '../assets/images/arrow-right.png'
import ImgArrowLeft from '../assets/images/arrow-left.png'
import TitleImage from '../assets/images/associates-title.png'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { ScrollTop } from 'components/ScrollTop'

const AssociatesName = [
   'ABEL MAGALHAES',
   'ADELAIDE CARDOSO LAGES',
   'ADEMILTON FERNANDES SILVA',
   'ADILSON RENOR JUNIOR',
   'ADONIS ROCO ALONSO',
   'ADRIANA APARECIDA DE SOUSA',
   'ADRIANA DA SILVA ARMELIN GODINHO',
   'ADRIANA F A FALCAO',
   'ADRIANA PINHO ROCHAA10',
   'ADRIANA SATIRO NARDOTTO',
   'AGUIMAR APARECIDA ARAUJO',
   'AILSON DE SOUSA MENDES',
   'ALBANICE MENDES DE VASCONCELOS',
   'ALBERTO MIGUEL PECCI',
   'ALDO DINIZ DA CRUZ JUNIOR',
   'ALESSANDRO GONCALVES PEREIRA',
   'ALEXANDRE CHIARATTIDO NASCIMENTO',
   'ALEXANDRE TORRES DA CUNHA',
   'ALEXANDRINA DOS SANTOS MARTINS',
   'ALGACIR TRICHES',
   'ALICE OLIVEIRA',
   'ALINE DE FATIMA LOPES OLIVEIRA',
   'ABEL MAGALHAES',
   'ADELAIDE CARDOSO LAGES',
   'ADEMILTON FERNANDES SILVA',
   'ADILSON RENOR JUNIOR',
   'ADONIS ROCO ALONSO',
   'ADRIANA APARECIDA DE SOUSA',
   'ADRIANA DA SILVA ARMELIN GODINHO',
   'ADRIANA F A FALCAO',
   'ADRIANA PINHO ROCHAA10',
   'ADRIANA SATIRO NARDOTTO',
   'AGUIMAR APARECIDA ARAUJO',
   'AILSON DE SOUSA MENDES',
   'ALBANICE MENDES DE VASCONCELOS',
   'ALBERTO MIGUEL PECCI',
   'ALDO DINIZ DA CRUZ JUNIOR',
   'ALESSANDRO GONCALVES PEREIRA',
   'ALEXANDRE CHIARATTIDO NASCIMENTO',
   'ALEXANDRE TORRES DA CUNHA',
   'ALEXANDRINA DOS SANTOS MARTINS',
   'ALGACIR TRICHES',
   'ALICE OLIVEIRA',
   'ALINE DE FATIMA LOPES OLIVEIRA',
   'ABEL MAGALHAES',
   'ADELAIDE CARDOSO LAGES',
   'ADEMILTON FERNANDES SILVA',
   'ADILSON RENOR JUNIOR',
   'ADONIS ROCO ALONSO',
   'ADRIANA APARECIDA DE SOUSA',
   'ADRIANA DA SILVA ARMELIN GODINHO',
   'ADRIANA F A FALCAO',
   'ADRIANA PINHO ROCHAA10',
   'ADRIANA SATIRO NARDOTTO',
   'AGUIMAR APARECIDA ARAUJO',
   'AILSON DE SOUSA MENDES',
   'ALBANICE MENDES DE VASCONCELOS',
   'ALBERTO MIGUEL PECCI',
   'ALDO DINIZ DA CRUZ JUNIOR',
   'ALESSANDRO GONCALVES PEREIRA',
   'ALEXANDRE CHIARATTIDO NASCIMENTO',
   'ALEXANDRE TORRES DA CUNHA',
   'ALEXANDRINA DOS SANTOS MARTINS',
   'ALGACIR TRICHES',
   'ALICE OLIVEIRA',
   'ALINE DE FATIMA LOPES OLIVEIRA',
   'ALINE DE SOUSA PEREIRA AVELINO',
   'ALINE FERNANDA MORAES JUSTINIANO COSSIO',
   'ALINE RAMOS MOTTA',
   'ALINE URSINE KRETTLI',
   'ALLAN DE ASSIS PINHEIRO',
   'ALONSO RODRIGUES RIBEIRO',
   'ANA',
   'ANA BEATRIZ AMORIM GOES',
   'ANA CAROLINA MOLINA ALMEIDA',
   'ANA LIDIA SCANAVINI',
   'ANA LUCIA GRANADO',
   'ANA MARIA RIBEIRO',
   'ANA PAULA',
   'ANA PAULA F M LEITE',
   'ANA PAULA MURO',
   'ANA PAULA RIBEIRO DE CARVALHO FERREIRA',
   'ANDERSON BOCCHI DE MORAES',
   'ANDRE',
   'ANDRE HENGLES DE OLIVEIRA SILVA',
   'ANDRE LUIZ GREGORIN',
   'ANDRE SAPOZNIK',
   'ANDREA DE LIMA E SYLOS',
   'ANDREIA CRISTINA SALES ROGGERO',
   'ANGELA MARIA CALCAGNOTTO',
   'ANNA CHRISTINA PEREIRA VARELLA',
   'ANTON LUDWIG MACIEL DE SAMPAIO',
   'ANTONIO',
   'ANTONIO CARLOS BERCOT',
   'ANTONIO OZELIN',
   'ANTONIO VISCONTI',
   'ARNALDO PALMA',
   'AZIZ CALIL FILHO',
   'BARBARA ALMEIDA',
   'BEATRIZ MARTINS FIGUEIRA',
   'BERNARDO DE AZEVEDO SILVA ROTHE',
   'BERNARDO ERWIN PUHLER RESENDE',
   'BERTA MARCHIORI',
   'BETO CARVALHO',
   'BRUNO FERREIRA DOS SANTOS SILVA',
   'BRUNO FIORE CASADO',
   'BRUNO GAIOSO BERG',
   'BRUNO LEANDRO NOVAES',
   'BRUNO LINTZ DOS SANTOS',
   'BRUNO PERES',
   'CAMILA CONTATTO',
   'CAMILA SIMOES PERES',
   'CAMILLA FERREIRA GOMES',
   'CARLA DAVANZO',
   'CARLA MARIA FONSECA MAIOTTO',
   'CARLOS',
   'CARLOS DE MEDEIROS DELCIDIO',
   'CARLOS EDUARDO CASTELLO BRANCO',
   'CARLOS EDUARDO PINTO LUGARINHO',
   'CARLOS GUSTAVO DEMANTOVA',
   'CARLOS HENRIQUE CARPINTER',
   'CARLOS OSWALDO BOTELHO GADELHA FILHO',
   'CARLOS VALENTIM',
   'CARMEN LUCIA CHAVES BONECKER CHAVES',
   'CARMEN MARTINS DE ALMEIDA',
   'CAROLINA AIRES MUCCILLO',
   'CAROLINA ANDRADE LELLA',
   'CASSIO DREYFUSS',
   'CATARINA MARIA CAVALCANTE XAVIER',
   'CELINA GUSMAO',
   'CESAR JOSE DE CAMPOS',
   'CHERRINE CARDOSO',
   'CIBELE FERNANDES DE OLIVEIRA GIL',
   'CINARA CARDOSO',
   'CINTIA MARIA DE LIMA',
   'CLARISSA DAMASO',
   'CLAUDIA BETTINI',
   'CLAUDIA SILVA DE OLIVEIRA',
   'CLAUDIO HENRIQUE MARIANO SILVA',
   'CLOVIS ROBERTO TONIOLLI',
   'CRISTIANE MOREIRA',
   'CRISTIANE SILVA COSTA',
   'CRISTINA PIMENTA GARCIA',
   'CRISTINA SOREANU PECEQUILO',
   'CRISTINA TAKEDA',
   'CYNTHIA SANTOS SOARES',
   'DANIEL ALVES DE CASTRO NASCIMENTO',
   'DANIEL FIGUEIREDO TRINDADE',
   'DANIEL SILVA PEREIRA',
   'DANIELA CRISTINA LI',
   'DANIELA LAURENTI CASTAGNA',
   'DANIELA MARIA DA SILVA',
   'DANIELE MUNIZ BATISTA',
   'DEBORA APARECIDA DE FRANCA',
   'DENIO TEIXEIRA SILVA',
   'DENISE CRINITTI',
   'DENISE ELISABETE DOS SANTOS',
   'DENNIS KAC',
   'DESIE MACHADO RIBEIRO',
   'DIEGO ANTONIO ALBUIXECH HRYCYLO',
   'DIOGO DORTA',
   'EDGAR FRANCISCO FERNANDES',
   'EDILSON RODRIGUES DE NOVAIS',
   'EDJANE PEREIRA DA SILVA',
   'EDSON KOITI YASHIKAWA',
   'EDSON MORIMOTO',
   'EDSON ROLIN DE MOURA',
   'EDUARDO ALVES DOS RAMOS',
   'EDUARDO MEDEIROS SAMPAIO',
   'EDUARDO MODENESE FILHO',
   'EDUARDO PICELLI VICENTIM',
   'EDUARDO ZAMBRANDO SANCHES',
   'ELBER CAETANO CHAVES CARVALHO',
   'ELCIO ESPINDOLA',
   'ELIEZIO GOMES DE SOUSA',
   'ELISABETH MOTTA',
   'ELISANGELA LOPES DA TRINDADE',
   'ELISANGELA PEREIRA DE SOUZA',
   'ELIZABETH DA COSTA',
   'ELOA RAGONHA',
   'ELVIRA MARIA LOUREIRO COLNAGO',
   'EMANUELA REIS BROD',
   'ENEDINA ALVES DE OLIVEIRA',
   'ERICA ROCHA BOMFIM',
   'ESTHER AMIACH IGIELKA',
   'EUCLIDES CARLI FILHO',
   'EVAH LYSA KOWARSKI',
   'EVELIN KOVALIAUSKAS CUSTODIA',
   'EWERTON SANTOS CAPARROZ',
   'FABIO ARTUR BERGER',
   'FABIO CAIO',
   'FABIO SARABIA',
   'FANNY SILVEIRA E SILVA',
   'FELICIA ROBERTA SA BRITTO ANTUNES',
   'FELIPE ANDRADE SILVA',
   'FELIPE BEZERRA',
   'FELIPE CABRAL FERREIRA',
   'FELIPE MAIMON',
   'FELIPE PESSOA DE LUCENA',
   'FELIPE TOCCI',
   'FERNANDA CAROLINE DE OLIVEIRA DA FONSECA',
   'FERNANDA DORETO RODRIGUES',
   'FERNANDA FRANCISCO',
   'FERNANDA SARAIVA GUSMAO',
   'FERNANDO A M COSTA',
   'FERNANDO AUGUSTO MARTINS VENTURA',
   'FERNANDO BATISTELI CAMARGO',
   'FERNANDO FERREIRA CIPRIANO',
   'FERNANDO JOSE SOARESDA CUNHA MATTOS',
   'FERNANDO PECHANKSY HELLER',
   'FLAVIA CRISTINA BATISTA AFONSO',
   'FLAVIA DA SILVA OLIVEIRA',
   'FLAVIA EID',
   'FLAVIA RIBAS DE MORAIS',
   'FLAVIO MOTTA CESAR',
   'FLAVIO SONNESSO',
   'FRANCISCO CARLOS NEVES',
   'FRANCISCO MARIA BAPTISTA',
   'FRANCISCO VIACAVA',
   'GABRIEL FONSECA SALES',
   'GABRIEL MACHADO DA SILVA',
   'GABRIELA RAIMUNDO',
   'GABRIELLE DE OLIVEIRA RAMOS',
   'GEORGE TELES DA COSTA',
   'GILBERTO CASTRO RODRIGUEZ FILHO',
   'GILDA ALVES COSTA PEREIRA',
   'GIOVANI BALEN MENEGHEL',
   'GIULIA DE OLIVEIRA TOFFOLO',
   'GLAUCIA BEATRIZ DE FREITAS LORENZETTI',
   'GLEYDS VASCONCELOS DA ROCHA',
   'GRAZIELE CRUVINEL',
   'GUILHERME RAJIME TAKAHASHI SARAIVA',
   'HALYSSON PORTELA DE SOUSA',
   'HEIMAR APARECIDA FONTES',
   'HELENA MEDEIROS MARTINS SANTOS',
   'HELOISA DO NASCIMENTO',
   'HENRIQUE CELSO ANGELO GONCALVES',
   'ICLEIA FERREIRA DE CARVALHO',
   'IDA HAWKINS',
   'IEDA APARECIDA LIMA',
   'IEDA SILVEIRA DE ALCANTARA',
   'ILDEMAR TIMOTEO FILHO',
   'INES DO AMARAL BUSCHEL',
   'ISABEL BASEI',
   'ISABEL CRISTINA MODESTO ALMADA',
   'ISADORA KAWAZU',
   'ISNALDO FRANCISCO DA SILVA JUNIOR',
   'ISRAEL SANCOVSKI',
   'IVAN XAVIER CONSORTI',
   'IVONE ROCHA GUEDES ALCOFORADO',
   'JACK GAVRA PILNIK',
   'JAIR LOPES MONTOIA',
   'JEAN',
   'JESSICA LANE CORDEIRO DA SILVA',
   'JESSICA TSCHEGE CAMARGO',
   'JOAO CARLOS ANTUNES',
   'JOAO GONCALVES NETO',
   'JOAO MAGALHAES XAVIER',
   'JOAO MANOEL DE ANDRADE DA SILVA',
   'JOAO MIGUEL BASSA',
   'JOAO PAULO QUINTAS PEREZ PARREIRAS HORTA',
   'JOAO TUNES JUNIOR',
   'JORGE CAMPOS JUNIOR',
   'JOSANE RODRIGUES BOECHAT',
   'JOSE AUGUSTO DE CAMPOS GUSMAO',
   'JOSE CARDOSO DE OLIVEIRA',
   'JOSE CARLOS AMARAL DE ALMEIDA',
   'JOSE CARLOS DA COSTA VIGUERA',
   'JOSE CARLOS SCUDELLER',
   'JOSE EDUARDO FIRES PEREIRA',
   'JOSE FRANCISCO RODRIGUES HENRIQUES',
   'JOSE HENRIQUE CORDEIRO HOSSRI',
   'JOSE RICARDO ARRUDATEMPERINI',
   'JOSE ROBERTO DOS SANTOS',
   'JOSE WILLIANS DE MENDONCA',
   'JOSELI NOGUEIRA DA SILVA HONORATO',
   'JOSIANA GORRERA VIANA',
   'JULIA GUIMARAES',
   'JULIANA ARANTES ROSA CURCI',
   'JULIANA MARQUES ROSSI',
   'JULIANA MATSUMOTO',
   'JULIANA P VARGAS',
   'JULIANO PANDOLFO',
   'JULIO DE CRASTO FERREIRA',
   'KAMILA APARECIDA QUINTAO MARTINS',
   'KAREM GABRIELLY BRAGA DE ANDRADE MAGALHAES',
   'KARINA DE MELO MACEDO',
   'KARINA GRAZZIELA FIORAVANTI RAMALHO SENSALES',
   'KATE MOTTA',
   'KATIA DIAS DOS SANTOS',
   'LARISSA DOMINGUES',
   'LARISSA NASCIMENTO',
   'LARISSA OSORIO',
   'LARISSA PERRONI MOTA',
   'LARISSA TEBALDI DOS REIS',
   'LAURA ANTONIA CASTRO DE MOURA',
   'LAURA PATRICIO',
   'LAVINIA WOTTRICH',
   'LAYS FELIX MOLEIRO',
   'LEANDRO COSTA',
   'LEANDRO MOREIRA CORREA',
   'LEANDRO PEREIRA MARQUES',
   'LIA MARIA CARLOTTI ZARPELON',
   'LIDIA ESTER LOPES DA SILVA',
   'LIGIA ALVES DOS SANTOS SOUZA',
   'LILIAN DE MEDEIROS BRUM BARRIONUEVO',
   'LIVIA CARDOSO',
   'LOUISE EMMY LONDON',
   'LUANA DANTAS',
   'LUCAS CHAVES',
   'LUCAS DOS SANTOS BRAUN',
   'LUCAS FERNANDO RIBEIRO JUKNEVICIUS',
   'LUCAS GOMES DOS SANTOS',
   'LUCAS GUSTAVO DE ANDRADE MADALENO',
   'LUCAS LAZZARINI',
   'LUCAS MONTEIRO BARBOSA',
   'LUCAS RODRIGUES DE BRITO',
   'LUCAS ROSSI GOMES',
   'LUCAS TADASHI KAKAZU',
   'LUCIANA TAVOLETTA ANGELOZZI',
   'LUCILIA ONDINA GALLI MANSO CABRAL',
   'LUCIO NUNES DA SILVA FILHO',
   'LUCY ALVARES PONTES',
   'LUIS ALBERTO VIEIRA DA ROCHA',
   'LUIS FERNANDO VIGATTO',
   'LUIS FILIPE ALVES DA SILVA',
   'LUIZ COSTA',
   'LUIZ MENEZES JR',
   'LUIZ PAULO BARBOSA RIBEIRO',
   'MANOEL FERNANDES DE OLIVEIRA',
   'MANUEL AVELINO RODRIGUES DE SOUSA PINHEIRO',
   'MANUELA CERQUEIRA LOPES',
   'MARA REGINA DIOGO GONCALVES',
   'MARCEL SACCO',
   'MARCELLA FUCHS SALOMAO',
   'MARCELLO TOLDI',
   'MARCELO JOSE SILVA MONTEIRO',
   'MARCELO MANGILI ANDRE',
   'MARCELO MARCHEL DOS SANTOS',
   'MARCELO MARCOS SCHWIRCK',
   'MARCELO SACENCO ASQUINO',
   'MARCELY SANTOS',
   'MARCIA CORONA',
   'MARCIA DIAS LIMA DA SILVA',
   'MARCIA LINS LEMGRUBER',
   'MARCIA MARIA GARCIA JORDAO',
   'MARCIA REGINA DIAS MOREIRA',
   'MARCIA REGINA VICTAL DOS SANTOS MARQUES',
   'MARCIA VIEIRA DE FRANCA',
   'MARCIA VILLACA ANDRY',
   'MARCIO GONCALVES MARTINS',
   'MARCIO SOARES DE ARAUJO',
   'MARCO ANDRE DE SA PAIXAO FERNANDES SOUZA',
   'MARCO ANTONIO DA CUNHA FILHO',
   'MARGARETH LUCAS VASQUEZ',
   'MARGARIDA GORETE DE MELLO BASTIANELLO',
   'MARIA ALICE DUARTE',
   'MARIA ALICE MAGALHAES DO CARMO',
   'MARIA APARECIDA DE LIMA CLARO',
   'MARIA BEATRIZ DE ARAUJO FRANCA',
   'MARIA CECILIA SANTOS TERRA',
   'MARIA CONCEICAO KISTNER DE AGUIAR',
   'MARIA DE FATIMA MARTINS PINHEIRO',
   'MARIA EDITH DIOGO NEGROMONTE',
   'MARIA FERNANDA',
   'MARIA HELENA FIGUEIREDO',
   'MARIA HELENA PATRICIO DE CARVALHO',
   'MARIA INES VILLA MELLO',
   'MARIA JOSE MOURA LHAMAS',
   'MARIA JULIA',
   'MARIA LUCIA BEZERRA PEDROZA',
   'MARIA LUCIA VILELA PASSOS',
   'MARIA TERESA CASARI ZANETTI CARDOZO',
   'MARIA ZULMA OLIVEIRA DE SOUZA',
   'MARIANA FERREIRA RODRIGUES VIEIRA',
   'MARINA KIYOMI KUWATOMI',
   'MARINNA VITORIA C P SILVA',
   'MARIO BRUSAROSCO DE ALMEIDA',
   'MARIO LINS ESTEVAM DE BARROS',
   'MARIZA LOPES DURAN',
   'MARLI MYLLIUS',
   'MARTA LUCIA PINTO SOUZA',
   'MARTA MELLO',
   'MARXUS VINICIUS ARROMATTE',
   'MARY YURI OKAHAMA',
   'MATEUS DE OLIVEIRA SILVA',
   'MATEUS PRAXEDES SOUZA',
   'MATHEUS HENRIQUE REINO MARTINS',
   'MATHEUS HORTAS RAPOSO',
   'MATHEUS SILVA CARVALHO',
   'MAURICIO DA PURIFICACAO',
   'MAURICIO GUIMARAES BERGERMAN',
   'MAURO FISBERG',
   'MAXSTALEY LENINYURI NEVES',
   'MAYRONN RAFAEL JACINTO',
   'MELINA RAMOS DE OLIVEIRA PIRES PLETNITZKI',
   'MICHAEL HAFRAN FILARDI',
   'MICHEL ANGELO PASTI',
   'MICHELE CRISTIAN DE SOUZA RAIMUNDO',
   'MIGUEL BRAIDO GOMES',
   'MIGUEL FERNANDES AVELINO',
   'MIRELLA',
   'MIRIAM MELHER',
   'MIRIAM PONTES BARBOSA',
   'MIRIAM TIDA NISHIKAWA',
   'MOISES MONTEIRO DA SILVA JUNIOR',
   'MONICA BECKER',
   'MONICA CHINELATO DE MENEZES VIANA',
   'MONTSERRAT MOTAS CARBONELL',
   'NADJA BRUNNER DE SOUSA PINHEIRO',
   'NADJA CARVALHO PEREIRA',
   'NADJA GOMES DA SILVA',
   'NARA SOLME BRONZONI',
   'NARA TORRES CARVALHO LISBOA',
   'NATALIA FREITAS HORTA',
   'NATALIA SBRISSA ROSSI',
   'NATASCHA FERREIRA CALOR',
   'NELSON GOULART DA SILVA',
   'NELSON SOUZA LIMA',
   'NILTON YAMADA',
   'ODETE FERREIRA PEIXOTO',
   'OSCAR DE ALMEIDA MELLO FREIRE FILHO',
   'OSNIEL FARIA DE OLIVEIRA',
   'OSVALDO ANTONIO DE SOUZA MUZY',
   'OSVALDO GIMENES',
   'PALMIRA V C S DOS ANJOS',
   'PATRICIA FORLANI MARQUES CORREA',
   'PATRICIA RACS',
   'PATRICIA SARAIVA',
   'PAULA CAROLINA ROMEIKA',
   'PAULA CRISTINA FERREIRA DE SOUZA',
   'PAULA FRANCINETE DE SANTANA TAVARES',
   'PAULA MORAES PEREIRA',
   'PAULA SAAD BONITO',
   'PAULA TUSSINI ONIDA',
   'PAULIANA PINHEIRO DA CRUZ',
   'PAULO CESAR AURICCHIO',
   'PAULO OMAR MISRAIM DA PAZ E SILVA',
   'PAULO SERGIO CARVALHO',
   'PEDRO HENRIQUE DE OLIVEIRA FERNANDES',
   'PEDRO HENRIQUE HANSEN PEREIRA',
   'PEDRO HENRIQUE VASCONCELOS FREITAS',
   'POLIANA DEOLINDO',
   'PRISCILA PAULA FRANCISCO',
   'PRISCILLA ARAUJO ALCICI JORGE',
   'RAFAEL BRUFATTO TOGNOLI',
   'RAFAEL MOREIRA ANDRADE',
   'RAFAEL PROCACI DA CUNHA',
   'RAISSA FONTELAS ROSADO GAMBI',
   'RALPH PANZUTTI',
   'RAQUEL MOURAO SANTOS CAMILLO',
   'RAQUEL PORTO RODRIGUES',
   'RAUL ANTONIO MARTINS DE FIGUEIREDO',
   'REGIANE TRINDADE DA SILVA',
   'REGINA  COCUROCI',
   'REGINA CELIA SALES NUNES',
   'REGINA SANTOS',
   'REINALDO RODRIGUES NUNES',
   'RENATA FERREIRA DE SA',
   'RENATA MARIA SAFE DE ANDRADE CARNEIRO',
   'RENATA PINTO VILHENA',
   'RENATA SEMIN',
   'RENATO ALEXANDRE SCOTT FILHO',
   'RENATO BARALDI RIBEIRO DA SILVA',
   'RENDRICK DUARTE AMORIM',
   'RETOUR',
   'REYNOR CARLINI BONILHA',
   'RICARDO ANTONIO PENSCHI',
   'RICARDO BARROS GURGEL',
   'RICARDO BRETZ COSTA',
   'RICARDO FERREIRA GAZOLI',
   'RICARDO MENEZES YAMANAKA',
   'RICARDO SAITO',
   'RICARDO YASUMITSU ARASHIRO',
   'ROBERTO',
   'ROBERTO FAGUNDES MORAES',
   'ROBERTO NADJ',
   'RODOLPHO MARTINS FERREIRA',
   'RODRIGO ALVARES CARNEIRO',
   'RODRIGO CEZAR DO ESPIRITO CASTOLDI',
   'RODRIGO DE ALMEIDA DIAS',
   'RODRIGO MAZZARONE GOMES DE SA',
   'RODRIGO MOTTA',
   'RODRIGO TIM DOS SANTOS',
   'ROGERIO DE ASSIS GAMBIM',
   'RONAN DO AMARAL FERNANDES',
   'ROSANA BARROS BOTTINI',
   'SANDRA ALVAREZ PONTES POLETTI',
   'SANDRA REGINA PONTE DA COSTA SALLES TOLEDO',
   'SANDRO CARVALHO',
   'SELMA F LEMES',
   'SELMA HERCULES MARCHETTI',
   'SERGIO DEBSKI',
   'SERGIO ROBERTO MALUF',
   'SHIRLEY DA SILVA CRUZ',
   'SIGRID DIAS',
   'SILMARA S GRACINDO',
   'SILVIA CRISTINA DALOIA LU,LIO',
   'SILVIA MARIA DALLA NORA LONDRES',
   'SILVIA REGINA SILVESTRINI',
   'SILVIA RICHIERI BORIN',
   'SILVIO DIAS LOPES',
   'SILVIO SHIZUTO',
   'SIMONE LAMOSA',
   'SIMONE PAULMICHL CORTE',
   'SOLANGE DE FARIA SANTOS',
   'SONIA MARA PRIMO MOREIRA',
   'SONIA REGINA COSTA',
   'STEFANY MUNHOZ',
   'SUZANA REITER CARVALHO',
   'SUZANA YUKICO OMORI DEL DEBBIO',
   'SUZANE DE ANDRADE BARBOZA',
   'SUZANE HELENA SMOLAREK DE BARROS',
   'SYLLAS TOZZINI',
   'TAIS RIBEIRO DE AZEVEDO VASCONCELLOS',
   'TALITA AIMOLA',
   'TALITA BERLIM REIS',
   'TAMARA SANTOS VIEIRA DE AGUIAR',
   'TAMIRYS MONTEIRO VELOSO',
   'TATIANA',
   'TATIANA BOYNARD',
   'TATIANA HOTIMSKY MILLNER',
   'TATIANA SUZUKI',
   'TERESA CRISTINA BOTELHO BRACHER',
   'THAIS AIB NUNES',
   'THAIS DOS SANTOS MOLINA',
   'THIAGO TAVARES GREGORIO',
   'THIAGO VINICIUS DOS SANTOS COSTA',
   'TIAGO DE MELLO LEANDRO FERREIRA',
   'TIAGO LOPES SILVA',
   'TIAGO OLIVEIRA DE JESUS',
   'TIAGO SOARES DOS REIS',
   'TIAGO SOBRAL',
   'VALDIR FERREIRA TEIXEIRA',
   'VALERIA CRISTINA PAIVA DE SOUZA',
   'VALERIA MACHADO DE SOUZA SILVA',
   'VALMIR MARCONI',
   'VANDETE RIBEIRO DE MENEZES FONSECA',
   'VANGLER BRIONES PEPELJI',
   'VERA REGINA RIBEIRO MARTINS CARIBE SIMMELHAG',
   'VERONICA DE SOUZA BOMFIM PINHEIRO',
   'VILMAR AUGUSTO AZEVEDO MIRANDA',
   'VINICIUS MIGUEL DIAS PEREIRA',
   'VINICIUS RODRIGUES RAMOS',
   'VIRGINIA SANCHES UIEDA',
   'VIVIANNE ALMEIDA OLIVEIRA',
   'WAGNER APARECIDO',
   'WAGNER ZAPPAROLI',
   'WALFREDO SOARES PESSANHA',
   'WESLEI FERREIRA MENDES',
   'WILLIAM LIMA SOUZA',
   'WILLIAM SADAMITSU YAMADA',
   'WU SHIN TING',
   'YVELISE PEREIRA VALLIM',
   'ZELIA MARIA BRITO MAIA MENDONCA',
   'ZELIA MARIA F. WAHMANN',
]

const PartnersName = [
   'ATACADAO SA',
   '2RM TECNOLOGIA DA INFORMACAO S/A',
   'A.W. FABER CASTELL S.A',
   'ACHE LABORATORIOS FARMACEUTICOS S.A',
   'ACL ASSESSORIA E ORG CONTABIL LTDA',
   'AGS ASSESSORIA DE GESTAO EM SAUDE EIRELI',
   'ALELO S.A',
   'ALPGREM ADMINISTRACAO DE CONDOMINIO LTDA',
   'ARCO IRIS ENCARTES PROMOCIONAIS LTDA EPP',
   'ASSOCIACAO DAS ADMINISTRADORAS DE BENS IMOVEIS E CONDOMINIOS DE SAO PA',
   'ASSOCIACAO LENCOENSE DE INCENTIVO A CULTURA',
   'ASSOCIACAO NOSSA SENHORA DO CARMO',
   'ASSOCIACAO SAO FRANCISCO DE ASSIS',
   'BANCO ITAUCARD S/A',
   'BEETHOVEN MUSIC CENTER S C LTDA',
   'BESINS HEALTHCARE BRASIL COMERCIAL E DISTRIBUIDORA',
   'BIOLAB SANUS FARMACEUTICA LTDA',
   'BIOSOFT IND E COM DE PROD ALIM LTDA',
   'BLAU FARMACEUTICA S/A',
   'BLUE CYCLE E FISHING DISTRIBUIDORA S/A',
   'BOSTON SCIENTIFIC DO BRASIL LTDA',
   'BRIDA LUBRIFICANTES LTDA',
   'BS DIGITAL MARKETING PERFORMANCE',
   'CABOT BRASIL INDUSTRIA E COMERCIO LTDA',
   'CASA SANTA LUZIA IMPORTADORA LTDA',
   'CBSM - COMPANHIA BRASILEIRA DE SERVICOS DE MARKETI',
   'CENTRO DE INTEGRACAO EMPRESA ESCOLA CIEE',
   'CENTRO SANEAMENTO E SERV. VANCADOS S.A',
   'CLICKSIGN GESTAO DE DOCUMENTOS S.A.',
   'COMBUSTRAN DERIVADOS DE PETROLEO LTDA',
   'CONTEMP INDUSTRIA E COMERCIO SERVICOS LTDA',
   'COPASTUR VIAGENS E TURISMO LTDA',
   'COSTA LION LTDA',
   'CRUZOLEO DERIVADOS DE PETROLEO LTDA',
   'DESTRA LUBRIFICANTES LTDA',
   'EDITORA FTD S.A.',
   'EDITORA MELHORAMENTOS LTDA.',
   'EXATTO EDUCACIONAL LTDA',
   'EXPRESSO SAO MIGUEL S/A',
   'FARMACIA ABDS EIRELI PEP',
   'FARMACIA DE MANIPULACAO SAINT GERMAIN',
   'FM DO BRASIL SERVICOS DE PREVENCAO DE PERDAS LTDA',
   'FUNDACAO LUCIA E PELERSON PENIDO',
   'FUSAO DERIVADOS DE PETROLEO LTDA',
   'GALZERANO IND. DE CARRINHOS E BERCOS LTDA.',
   'GAREN AUTOMACAO S.A',
   'HEINZ-PETER ELSTRODT',
   'IBIUNA MACRO GESTAO DE RECURSOS LTDA',
   'INSTITUTO ARREDONDAR',
   'INSTITUTO BI&P DE SUSTENTABILIDADE',
   'JAEPEL PAPEIS E EMBALAGENS S.A',
   'JAL INDUSTRIA E COMERCIO DE FERRO E ACO LTDA',
   'JOHNSON & JOHNSON DO BRASIL IND E COM DE PRODUTOS PARA A SAUDE',
   'LABORATORIO CARLOS CHAGAS LTDA',
   'LIVELO S.A',
   'M.M. ASSESSORIA A EVENTOS CONGRESSOS E PROMOCOES  EIRELI',
   'MAGAZINE TORRA TORRA LTDA',
   'MARBELLA COZINHA JAPONEZA LTDA',
   'MAROIL DERIVADOS DE PETROLEO LTDA',
   'MCI GROUP - BRAZIL',
   'META RECRUTAMENTO E SELECAO DE PESSOAL LTDA',
   'MICROSOF AZURE',
   'MONTANA QUIMICA S.A.',
   'NEW SPACE PROCESSAMENTO E SISTEMAS LTDA',
   'NMQ COMERCIO DE MAQUINAS E EQUIPAMENTOS LTDA',
   'OLIVEIRA TRUST DISTR. DE TITULOS VALORES',
   'OPTIMA CORRETORA DE SEGUROS LTDA',
   'PAGSEGURO INTERNET LTDA',
   'PET SUPPLIES COMERCIAL LTDA.',
   'PETROCAMP DERIVADOS DE PETROLEO LTDA',
   'PRESENTE CONSCIENTE',
   'PRICEWATERHOUSECOOPERS AUDITORES INDEPENDENTES LTDA',
   'PRISMAH FIDELIDADE LTDA',
   'RA GOMES PACHECO LTDA',
   'RAIA DROGASIL SA',
   'RISEL COMBUSTIVEIS LTDA',
   'SCANSOURCE BRASIL DISTRIBUIDORA DE TECNOLOGIAS LTDA',
   'SCHOTT BRASIL LTDA',
   'SECRETARIA DA FAZENDA DO ESTADO DE SAO PAULO',
   'SUPER DIESEL DERIVADOS DE PETROLEO LTDA',
   'SUZANO HOLDING S/A',
   'TOKIO MARINE SEGURADORA SA',
   'TRANSUNION BRASIL SISTEMAS EM INFORMATIC',
   'TRIANA EDUCACAO LTDA',
   'UNIT-IND. COMERCIO IMPORTACAO E EXPORTACAO LTDA',
   'VALFILM MG INDUSTRIA DE EMBALAGENS LTDA',
   'VIS OTICA LTDA ME',
   'VISA DO BRASIL EMPREENDIMENTOS LTDA',
   'WHITE MARTINS GASES INDUSTRIAIS DO NORDESTE LTDA.',
   'WICKBOLD & NOSSO PAO INDUSTRIAS ALIMENTICIAS LTDA',
   'WLM PARTICIPACOES  COMERCIO DE MAQUINAS E VEICULOS S.A',
   'ORACLE DO BRASIL LTDA',
]

const Associates: React.FC = () => {
   const navigate = useNavigate()
   const [text, setText] = useState('')

   ScrollTop()

   return (
      <BaseLayout>
         <Body>
            <Section>
               <Header>
                  <TitleImg src={TitleImage} alt="Sócios" />
                  <InputArea>
                     <input
                        type="text"
                        placeholder="Busque pelo seu nome"
                        onChange={(e) => setText(e.target.value)}
                     />
                     <button>BUSCAR</button>
                  </InputArea>
               </Header>
               <div>
                  <AssociatesContainer>
                     {AssociatesName.filter((val) => {
                        if (text == '') {
                           return val
                        } else if (
                           val.toLowerCase().includes(text.toLowerCase())
                        ) {
                           return val
                        }
                     }).map((name, index) => (
                        <li key={index}>{name}</li>
                     ))}
                  </AssociatesContainer>

                  <Title>Parceiros</Title>

                  <AssociatesContainer>
                     {PartnersName.filter((val) => {
                        if (text == '') {
                           return val
                        } else if (
                           val.toLowerCase().includes(text.toLowerCase())
                        ) {
                           return val
                        }
                     }).map((name, index) => (
                        <li key={index}>{name}</li>
                     ))}
                  </AssociatesContainer>
               </div>
            </Section>
         </Body>
         <Footer>
            <FooterContainer>
               <div onClick={() => navigate('/parceiros')}>
                  <img src={ImgArrowLeft} alt="" />
                  <p>Parceiros </p>
               </div>
               <div>
                  <p>Expediente</p>
                  <img
                     onClick={() => navigate('/expediente')}
                     src={ImgArrowRight}
                     alt=""
                  />
               </div>
            </FooterContainer>
         </Footer>
      </BaseLayout>
   )
}

export default Associates

const Body = styled.div`
   margin-top: -16%;
   padding-top: 16%;
   padding-bottom: 150px;
   overflow-x: hidden;
   position: relative;
   min-height: 600px;
`
const Header = styled.div`
   display: flex;
   justify-content: space-between;
   @media (max-width: 860px) {
      flex-direction: column;

      img {
         margin-bottom: 20px;
      }
   }
`

const TitleImg = styled.img`
   max-width: 220px;
`

const InputArea = styled.div`
   max-width: 550px;
   width: 100%;
   align-items: center;
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;

   input {
      max-height: 50px;
      outline: none;
      max-width: 440px;
      width: 100%;
      background: linear-gradient(180deg, #ffffff 22.12%, #ececec 87.93%);
      border: 1px solid #d0d2d3;
      border-radius: 4px;
      padding: 11px 15px;
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      ::placeholder {
         font-family: 'Roboto Condensed';
         font-style: normal;
         font-weight: 500;
         font-size: 18px;
         line-height: 28px;
         padding-left: 5px;
      }
   }
   button {
      max-height: 50px;

      background: linear-gradient(180deg, #fa509e 0%, #d31f72 100%);
      border: 1px solid #d0d2d3;
      border-radius: 4px;
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #ffffff;
      padding: 5px 10px;

      @media (max-width: 580px) {
         margin-top: 10px;
      }
   }
`

const Section = styled.div`
   margin: auto;
   max-width: 1441px;
   display: flex;
   flex-direction: column;
   padding: 0 5%;
   position: relative;

   > div {
      width: 100%;

      > h1 {
         margin-top: 20px;
         font-family: 'Roboto Condensed';
         font-style: normal;
         font-weight: 700;
         font-size: 28px;
         line-height: 34px;
         color: #303030;
      }

      > img {
         width: 100%;
         max-width: 250px;
      }

      > p {
         margin-top: 30px;
         max-width: 620px;
         font-family: 'Roboto Condensed';
         font-style: normal;
         font-weight: 500;
         font-size: 18px;
         line-height: 28px;
         color: #000000;

         > span {
            background-color: #f162a5;
            color: #fff;
         }
      }
   }
`
const Footer = styled.div`
   width: 100%;
   background-color: #ffffff;
   border-top: 2px solid black;
   margin-top: 80px;
   padding: 0 5px;
`

const FooterContainer = styled.div`
   max-width: 1441px;
   margin: auto;
   background-color: #ffffff;
   display: flex;
   align-items: center;
   display: flex;
   justify-content: space-between;
   padding: 40px 0px;
   div {
      display: flex;
      align-items: center;
      img {
         cursor: pointer;
      }
   }

   p {
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      text-align: right;
      text-transform: uppercase;
      color: #000000;
      margin: 0 5px;
   }
`

const Title = styled.div`
   margin-top: 40px;
   font-family: 'Roboto Condensed';
   font-style: normal;
   font-weight: 700;
   font-size: 24px;
   line-height: 34px;
   color: #303030;
`

const AssociatesContainer = styled.ul`
   margin-top: 20px;
   display: flex;
   flex-direction: column;
   flex-wrap: wrap;
   width: 100%;
   height: 5500px;

   :last-of-type {
      height: 1500px;
      @media (max-width: 500px) {
         height: 3400px;
      }
   }

   li {
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #303030;
   }

   @media (max-width: 1030px) {
      height: 1000px;
   }
   @media (max-width: 690px) {
      height: auto;
   }
`
